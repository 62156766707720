import { useEffect } from "react";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";
import { useStatsig } from "@/shared/hooks/useStatsig";
import { PublicConfig } from "@/shared/PublicConfig";
import { AnalyticsUtils } from "@/shared/utils/AnalyticsUtils";

const AdminFeeHook = () => {
    const { appState, updateAppState } = useAppLayerContext();
    const { quoteQuery: { data: quote } = {} } = appState;

    const { showAdminFee } = useStatsig({
        layerId: PublicConfig.STATSIG_LAYER_ID_MAIN,
        layerParameters: [{ key: "showAdminFee", defaultValue: false }] as const,
        queryParams: AnalyticsUtils.getQueryParams(quote?.extra?.queryParams)
    });

    useEffect(() => {
        updateAppState({ showAdminFee });
    }, [showAdminFee, updateAppState]);
    return null;
};

export const AdminFeeTestWrapper = ({ isEnabled }: { isEnabled: boolean }) => {
    return <>{isEnabled && <AdminFeeHook />}</>;
};
