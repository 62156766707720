import { UIUtils } from "@/shared/utils/UIUtils";
import { Heading } from "@/shared/components/ui";
import { cn } from "@/shared/utils";
import { useEffect, useState } from "react";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";

interface PolicySummaryFooterProps {
    policiesCount: number;
    transactionFee: number;
    billingFrequency: "monthly" | "yearly";
    totalPrice: number;
    isSingleCol?: boolean;
    summaryStyleVariant: string;
}

const PolicySummaryFooter = (props: PolicySummaryFooterProps) => {
    const { policiesCount, transactionFee, billingFrequency, totalPrice, isSingleCol = false, summaryStyleVariant } = props;
    const { appState } = useAppLayerContext();
    const { showAdminFee, priorityCode } = appState;
    //Store the monthly fee to calculate (monthlyFee * 12) in annual mode.
    const [storedMonthlyFee, setStoredMonthlyFee] = useState(() => {
        return billingFrequency === "monthly" ? transactionFee : 0;
    });

    /* 
        Edge case: if the user toggles to annual, changes ZIP (to no fee), and returns
        to billing step in annual mode, we won’t see the updated monthly fee until they 
        toggle back to monthly.
    */
    useEffect(() => {
        if (billingFrequency === "monthly") {
            setStoredMonthlyFee(transactionFee);
        }
    }, [billingFrequency, transactionFee]);

    const isAnnualBilling = billingFrequency === "yearly";

    const feeToDisplay = isAnnualBilling ? storedMonthlyFee * 12 : transactionFee;

    const formattedTotalPrice = UIUtils.formatNumber(totalPrice ?? 0, 2);

    // Container styles
    const dynamicPaddingClass = summaryStyleVariant === "default" ? "px-4 pb-4" : "p-4";
    const dynamicColumnsClass = !isSingleCol && "sm:grid-cols-2 sm:gap-12";

    // Text styles
    const rowLabelStyles = summaryStyleVariant === "default" ? "text-base" : "text-base sm:text-lg";
    const rowValueStyles = summaryStyleVariant === "default" ? "text-base" : "text-lg sm:text-xl";

    return (
        <div className={cn("grid text-content-primary", dynamicPaddingClass, dynamicColumnsClass)}>
            <div></div>
            <div className="flex flex-col gap-2">
                <div
                    className={cn("flex flex-row justify-between text-xs", {
                        "line-through": isAnnualBilling && feeToDisplay > 0 && !showAdminFee
                    })}
                >
                    <p>Monthly Transaction Fee</p>
                    <p className="flex gap-2">
                        <b
                            className={cn({
                                "line-through": showAdminFee && isAnnualBilling && feeToDisplay > 0,
                                "text-content-success": feeToDisplay === 0 && isAnnualBilling && showAdminFee
                            })}
                        >
                            {UIUtils.formatCurrency(feeToDisplay)}
                        </b>
                        {isAnnualBilling && showAdminFee && feeToDisplay > 0 && <b className="text-content-success">$0.00</b>}
                    </p>
                </div>

                {/* /////// Statsig Test //////////////////// */}
                {showAdminFee && (
                    <div className="flex flex-row justify-between text-xs">
                        <p className={cn({ "line-through": !isAnnualBilling })}>Admin Fee</p>
                        <p className="flex gap-2">
                            <b className="line-through">$15.00</b>
                            {isAnnualBilling && <b className="text-content-success">$0.00</b>}
                        </p>
                    </div>
                )}

                <div className="flex flex-row justify-between">
                    <Heading level="h4" className={cn("font-bold", rowLabelStyles)}>
                        Grand Total
                    </Heading>
                    <div className={cn(rowValueStyles, "font-semibold")}>
                        <span data-testid="policy-summary-total-price" data-value={formattedTotalPrice}>
                            {`(${policiesCount} ${policiesCount === 1 ? "Pet" : "Pets"}) $${formattedTotalPrice}`}
                        </span>
                        /{isAnnualBilling ? "yr" : "mo"}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PolicySummaryFooter;
