import { useEffect, useCallback, useRef } from "react";
import { useSearchParams } from "next/navigation";
import { UIUtils } from "../utils/UIUtils";

interface DataDogForceRecordingProps {
    isFeatureEnabled: boolean;
    isRecordingEnabled: boolean;
}
// SSR check helper
const hasWindow = typeof window !== "undefined";

const useDataDogForceRecording = ({ isFeatureEnabled, isRecordingEnabled }: DataDogForceRecordingProps) => {
    const isRecordingStarted = useRef(false);
    const params = useSearchParams();
    const hasForceRecordingParam = !!UIUtils.getCaseInsensitiveValue(params, "spot-r");

    /**
     * Initialize DataDog session recording
     * Wrapped in useCallback to prevent unnecessary re-renders
     */
    const startRecording = useCallback(() => {
        const dd = window?.DD_RUM;
        if (!dd) {
            console.warn("DataDog RUM not initialized");
            return;
        }
        console.log(`Starting DataDog recording...`);
        try {
            dd.startSessionReplayRecording({ force: true });
            isRecordingStarted.current = true;
        } catch (error) {
            console.error("Failed to start DataDog recording:", error);
        }
        dd.addAction("recording-condition-triggered");
    }, []);

    /**
     * Start recording when conditions are met:
     * - Feature flag is enabled
     * - isRecordingEnabled is true
     * - spot-r param is not present (if it is, we have already set the recording rate to 100)
     * - We're in a browser environment
     * - DataDog RUM is initialized
     */
    useEffect(() => {
        if (!isFeatureEnabled || !hasWindow || isRecordingStarted.current || hasForceRecordingParam) return;

        const dd = window?.DD_RUM;
        if (!dd?.onReady) {
            console.warn("DataDog RUM not initialized");
            return;
        }

        const shouldStartRecording = isRecordingEnabled;
        if (!shouldStartRecording) return;

        dd.onReady(startRecording);
    }, [hasForceRecordingParam, isFeatureEnabled, isRecordingEnabled, startRecording]);
};

export default useDataDogForceRecording;
