import React, { FunctionComponent, useCallback } from "react";
import { Button } from "@/shared/components/ui/Button";
import { useModal } from "@/shared/hooks/useModal";
import FullCoverageDetailsModal from "@/quote-ptz-us/src/components/FullCoverageDetailsModal";
import InfoIcon from "@/shared/media/icons/CircleInfoProRegular.svg";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";

const Exclusions = () => {
    const modal = useModal();

    const handleOpenModal = useCallback(
        (ModalComponent: FunctionComponent<any>, modalProps?: any, initialTab: "covered" | "notCovered" = "notCovered") => {
            modal.openModal(ModalComponent, { ...modalProps, initialTab });
        },
        [modal]
    );

    // Statsig - reduced-height
    const { hasReducedHeight } = useAppLayerContext().appState;

    return (
        <div className="mt-3 flex flex-col gap-1 rounded-md bg-background-brand-b-secondary p-4 sm:mx-auto md:items-center">
            <h4 className="text-sm font-bold text-content-brand-b">What isn’t covered under Spot plans?</h4>
            {hasReducedHeight ? (
                <div className="text-xs text-content-brand-b">
                    Pre-existing conditions, breeding/pregnancy care, & cosmetic surgeries.{" "}
                    <span>
                        <Button
                            onClick={() => handleOpenModal(FullCoverageDetailsModal)}
                            className="p-0 text-xs font-bold text-content-brand-b underline"
                            variant="ghost"
                            size="sm"
                        >
                            View All Exclusions
                        </Button>
                    </span>
                </div>
            ) : (
                <>
                    <ul className="flex list-disc flex-col pl-5 text-xs text-content-brand-b sm:flex-row sm:justify-center sm:space-x-10">
                        <li>Pre-existing conditions</li>
                        <li>Breeding or pregnancy care</li>
                        <li>Cosmetic surgeries</li>
                    </ul>
                    <div>
                        <Button
                            onClick={() => handleOpenModal(FullCoverageDetailsModal)}
                            className="text-xs text-content-brand-b"
                            variant="ghost"
                            startDecorator={<InfoIcon className="h-4" />}
                            size="sm"
                        >
                            View All Exclusions
                        </Button>
                    </div>
                </>
            )}
            {modal.render}
        </div>
    );
};

export default Exclusions;
