// components
import DetailRow from "../DetailRow";
import { Badge, Heading } from "@/shared/components/ui";
import { PolicySummaryConfig } from "./index";
import { Accordion, AccordionItem, AccordionContent, AccordionTrigger } from "@/shared/components/ui/accordion";

// utils
import { UIUtils } from "@/shared/utils/UIUtils";
import { CoverageUtils } from "@/shared/utils/CoverageUtils";
import { cn } from "@/shared/utils";

// types
import { Policy, Quote } from "@/shared/types/Quote.interface";
import { PetBreed } from "spot-types/entities/PetBreed";
import { AnalyticsUtils } from "@/shared/utils/AnalyticsUtils";
import { useStatsig } from "@/shared/hooks/useStatsig";
import { PublicConfig } from "@/shared/PublicConfig";
interface PolicySummaryContentProps {
    quote: Quote;
    breeds: PetBreed[] | undefined;
    config?: PolicySummaryConfig;
    isAnnualBilling: boolean;
    summaryStyleVariant: string;
}

interface PolicySummaryRowProps {
    policy: Policy;
    breedName: string | undefined;
    isAnnualBilling: boolean;
    config?: PolicySummaryConfig;
    quote: Quote;
    summaryStyleVariant: string;
    isLast: boolean;
}

const PolicySummaryRow = (props: PolicySummaryRowProps) => {
    const { policy, breedName, isAnnualBilling, config, quote, summaryStyleVariant, isLast } = props;
    // Set config defaults:
    const { enableAccordionToggle = true, showPreDiscountPrice = true, useGenericDiscountLabel = false, isSingleCol = false } = config ?? {};

    const queryParams = AnalyticsUtils.getQueryParams(quote?.extra?.queryParams);

    // Statsig - billing-step-stacked-discount-1000
    const { stackedDiscountVariant } = useStatsig({
        layerId: PublicConfig.STATSIG_LAYER_ID_MAIN,
        layerParameters: [{ key: "stackedDiscountVariant", defaultValue: "default" }] as const,
        queryParams
    });
    // Statsig:end

    const {
        name,
        species,
        petAge,
        gender,
        basePrice,
        accidentCoverageDate,
        illnessCoverageDate,
        wellnessCoveragePrice,
        wellnessCoverageDate,
        annualLimit,
        annualDeductible,
        reimbursementRate
    } = CoverageUtils.formatPolicyDetails(policy);

    const genderText = gender === "M" ? `Male` : `Female`;
    const triggerText = "Toggle policy";
    const hasDifferentAccidentIllnessDates = !!illnessCoverageDate && accidentCoverageDate !== illnessCoverageDate;

    // Discounts
    const discounts = policy?.discounts ?? [];
    const hasDiscount = discounts.length > 0;
    const totalDiscount = hasDiscount ? discounts.map(discount => discount.amount ?? 0).reduce((previousValue, currentValue) => (previousValue ?? 0) + (currentValue ?? 0), 0) : 0;
    // Needed for generic discount label
    const discountAmount = policy?.discountAmount?.value ?? 0;
    const totalDiscountPercentage = discounts.map(discount => Math.round((1 - (discount.adjustment || 0)) * 100)).reduce((total, percentage) => total + percentage, 0);

    // Pricing
    const planTotal = Number(basePrice?.value ?? 0); //Total before discounts
    const planPremium = planTotal - (wellnessCoveragePrice ?? 0); //Total before discounts minus wellness
    const planTotalAdjusted = planTotal + totalDiscount; //Total after discounts
    const planPremiumAdjusted = planPremium + totalDiscount; //total premium after discounts minus wellness

    // Coverage Details
    const coverages = policy.coverageSettings?.coverages;
    const hasIllness = coverages?.some(coverage => coverage.type === "illness");
    const coverageLabel = `${hasIllness ? "Accident & Illness" : "Accident Only"} Coverage`;
    const coverageDescription = `After paying your ${annualDeductible.replace(/\.\d{2}$/, "")} annual deductible, ${reimbursementRate} of your eligible costs are reimbursed, with ${annualLimit === "Unlimited" ? "no" : annualLimit.replace(/\.\d{2}$/, "")} annual limit.`;

    // Wellness Details
    const wellnessCoverage = coverages?.find(coverage => coverage.type === "wellness-gold" || coverage.type === "wellness-platinum");
    const wellnessLabel = `${wellnessCoverage?.type === "wellness-platinum" ? "Platinum" : "Gold"} Preventive Care Coverage`;
    const wellnessDescription = `${wellnessCoverage?.type === "wellness-platinum" ? "Get reimbursed for vaccines, heartworm/flea prevention, and dental cleanings." : "Get reimbursed for routine services like vaccines and check-ups."}`;

    // Styles
    const petHeadingStyle = summaryStyleVariant === "default" ? "text-base" : "text-lg sm:text-xl";
    const headingStyles = summaryStyleVariant !== "default" ? "text-base font-bold text-content-brand-b grow" : "text-xs font-semibold";
    const textStyles = `${summaryStyleVariant === "default" ? "text-xs" : "text-sm"} leading-5 text-content-secondary`;
    const rowGapStyles = summaryStyleVariant !== "default" ? "space-y-2" : "";

    // Divider
    const shouldShowDivider = summaryStyleVariant === "default" || (summaryStyleVariant !== "default" && !isLast);

    const renderImportantDates = () => {
        const rows = [];

        if (wellnessCoverageDate) {
            rows.push(
                <DetailRow
                    key="wellness"
                    label="Preventive Care Coverage Starts"
                    value={CoverageUtils.calculateCoverageDate(wellnessCoverageDate)}
                    valueClass="font-bold text-content-primary"
                    wrapperClass={textStyles}
                />
            );
        }

        /* Accident & Illness have different dates */
        if (hasDifferentAccidentIllnessDates) {
            if (accidentCoverageDate) {
                rows.push(
                    <DetailRow
                        key="accident"
                        label="Accident Coverage Starts"
                        value={CoverageUtils.calculateCoverageDate(accidentCoverageDate)}
                        valueClass="font-bold text-content-primary"
                        wrapperClass={textStyles}
                    />
                );
            }

            if (illnessCoverageDate) {
                rows.push(
                    <DetailRow
                        key="illness"
                        label="Illness Coverage Starts"
                        value={CoverageUtils.calculateCoverageDate(illnessCoverageDate)}
                        valueClass="font-bold text-content-primary"
                        wrapperClass={textStyles}
                    />
                );
            }
        } /* Accident & Illness have same date */ else if (accidentCoverageDate && illnessCoverageDate) {
            rows.push(
                <DetailRow
                    key="combined"
                    label="Accident & Illness Coverage Starts"
                    value={CoverageUtils.calculateCoverageDate(illnessCoverageDate)}
                    valueClass="font-bold text-content-primary"
                    wrapperClass={textStyles}
                />
            );
        } /* Is Accident Only */ else if (accidentCoverageDate) {
            rows.push(
                <DetailRow
                    key="accidentOnly"
                    label="Accident Coverage Starts"
                    value={CoverageUtils.calculateCoverageDate(accidentCoverageDate)}
                    valueClass="font-bold text-content-primary"
                    wrapperClass={textStyles}
                />
            );
        }

        return rows;
    };

    const renderDiscounts = () => {
        if (!discounts.length || useGenericDiscountLabel) return null;

        if (stackedDiscountVariant === "default") {
            return discounts.map((discount, index) => (
                <DetailRow
                    key={index}
                    label={discount.name}
                    labelClass="text-content-success"
                    value={`${UIUtils.formatCurrency(discount.amount ?? 0)}`}
                    valueClass="font-bold text-content-success"
                    wrapperClass={textStyles}
                />
            ));
        }

        const discountAmountCurrency = UIUtils.formatCurrency(-Math.abs(totalDiscount));

        return (
            <DetailRow
                label={
                    <>
                        <b>{totalDiscountPercentage}% OFF</b>{" "}
                        {`(${discounts
                            .map(d => d.name?.replace(/\s*\d+% off/, ""))
                            .reverse()
                            .join(" + ")})`}
                    </>
                }
                labelClass="text-content-success"
                value={discountAmountCurrency}
                valueClass="font-bold text-content-success"
                wrapperClass={textStyles}
            />
        );
    };

    const renderPaymentDetails = () => {
        return (
            <>
                <DetailRow
                    label={`${isAnnualBilling ? "Annual" : "Monthly"} Premium`}
                    value={`${UIUtils.formatCurrency(planPremium)}`}
                    valueClass="font-bold text-content-primary"
                    wrapperClass={textStyles}
                />

                {renderDiscounts()}

                {useGenericDiscountLabel && discountAmount > 0 && (
                    <DetailRow
                        label="Discount"
                        value={`${UIUtils.formatCurrency(-Math.abs(discountAmount))}`}
                        valueClass="font-bold text-content-primary"
                        wrapperClass={`${textStyles} text-content-success`}
                    />
                )}
                {!!wellnessCoveragePrice && (
                    <DetailRow
                        label="Preventive Care Coverage"
                        value={`${UIUtils.formatCurrency(wellnessCoveragePrice ?? 0)}`}
                        valueClass="font-bold text-content-primary"
                        wrapperClass={textStyles}
                    />
                )}
            </>
        );
    };

    return (
        <>
            <AccordionItem value={policy.id!} className="flex flex-col gap-5 text-content-primary">
                <div className="flex items-start justify-between gap-4">
                    {/* Pet Info */}
                    <div className="min-w-0 flex-1">
                        <Heading level="h3" className={cn(petHeadingStyle, "font-bold leading-6")}>
                            <span className="whitespace-nowrap">
                                <span className="inline-block max-w-[calc(100%-1rem)] truncate align-bottom">{name}</span>
                                <span>&apos;s</span>
                            </span>
                            <span> Coverage</span>
                        </Heading>
                        <p className={textStyles}>
                            {species} - {petAge}, {genderText}, {breedName}
                        </p>
                    </div>
                    <div className="flex items-center gap-2">
                        {/* Price */}
                        <div className="flex flex-col">
                            {hasDiscount && showPreDiscountPrice && <span className="text-right text-content-secondary line-through">{UIUtils.formatCurrency(planTotal)}</span>}
                            <span className={`${hasDiscount && showPreDiscountPrice ? "text-content-success" : ""} ${petHeadingStyle} whitespace-nowrap font-bold`}>
                                {UIUtils.formatCurrency(planTotalAdjusted)}/{isAnnualBilling ? "yr" : "mo"}
                            </span>
                        </div>
                        {/* Arrow */}
                        {enableAccordionToggle && (
                            <AccordionTrigger aria-label={triggerText} className="h-6">
                                <span className="hidden">{triggerText}</span>
                            </AccordionTrigger>
                        )}
                    </div>
                </div>

                <AccordionContent className="pb-0">
                    <div className={cn("grid grid-cols-1 gap-5", !isSingleCol && "md:grid-cols-2 md:gap-x-6")}>
                        {summaryStyleVariant !== "billing-rearrange-3000" && (
                            <>
                                {/* Payment Details */}
                                <div className={rowGapStyles}>
                                    <Heading level="h4" className={headingStyles}>
                                        Payment Details
                                    </Heading>

                                    {renderPaymentDetails()}
                                </div>
                                {/* Coverage Details */}
                                <div className={rowGapStyles}>
                                    <Heading level="h4" className={headingStyles}>
                                        Coverage Details
                                    </Heading>
                                    <DetailRow label="Annual Limit" value={annualLimit} valueClass="font-bold text-content-primary" wrapperClass={textStyles} />
                                    <DetailRow label="Reimbursement Rate" value={reimbursementRate} valueClass="font-bold text-content-primary" wrapperClass={textStyles} />
                                    <DetailRow label="Annual Deductible" value={annualDeductible} valueClass="font-bold text-content-primary" wrapperClass={textStyles} />
                                </div>
                            </>
                        )}

                        {summaryStyleVariant === "billing-rearrange-3000" && (
                            <>
                                {/* Coverage Details */}
                                <div className="space-y-2">
                                    <div className="flex items-center gap-2">
                                        <Heading level="h4" className={headingStyles}>
                                            {coverageLabel}
                                        </Heading>
                                        <div className="flex flex-col justify-end">
                                            <div className="flex items-center justify-end gap-1">
                                                {hasDiscount && <span className="text-sm text-content-secondary line-through">{UIUtils.formatCurrency(planPremium, 2)}</span>}
                                                <span className="text-base font-bold text-content-primary">{UIUtils.formatCurrency(planPremiumAdjusted, 2)}</span>
                                            </div>
                                            {hasDiscount && (
                                                <Badge variant="secondary" className="whitespace-nowrap">
                                                    {totalDiscountPercentage}% Discount Applied
                                                </Badge>
                                            )}
                                        </div>
                                    </div>

                                    <div>{coverageDescription}</div>
                                </div>
                                {/* Wellness Details */}
                                {wellnessCoverage && (
                                    <div className="space-y-2">
                                        <div className="flex gap-2">
                                            <Heading level="h4" className={headingStyles}>
                                                {wellnessLabel}
                                            </Heading>
                                            <div className="text-base font-bold text-content-primary">${wellnessCoveragePrice}</div>
                                        </div>

                                        <div>{wellnessDescription}</div>
                                    </div>
                                )}
                            </>
                        )}

                        {/* Important Dates */}
                        {summaryStyleVariant === "default" && (
                            <div className={rowGapStyles}>
                                <Heading level="h4" className="text-xs font-bold text-content-primary">
                                    Important Dates
                                </Heading>

                                {renderImportantDates()}
                            </div>
                        )}
                    </div>
                </AccordionContent>
            </AccordionItem>
            {shouldShowDivider && <div className="border-b border-stroke-tertiary"></div>}
        </>
    );
};

const PolicySummaryContent = (props: PolicySummaryContentProps) => {
    const { quote, breeds, config, isAnnualBilling, summaryStyleVariant } = props;
    const policies = quote?.policies ?? [];
    const policyIds: Policy["id"][] = [];

    const summaryRows = policies.map((policy, index) => {
        // Policy ids needed to open all policies in the accordion by default
        policyIds.push(policy.id);
        const breedName = breeds?.find(breed => breed.id === policy.breedID)?.name ?? "";
        const isLast = index + 1 === policies.length;
        return (
            <PolicySummaryRow
                policy={policy}
                breedName={breedName}
                isAnnualBilling={isAnnualBilling}
                key={index}
                config={config}
                quote={quote}
                summaryStyleVariant={summaryStyleVariant}
                isLast={isLast}
            />
        );
    });

    return (
        <Accordion type="multiple" defaultValue={policyIds.filter(id => id !== undefined) as string[]} className="flex flex-col gap-5 p-4">
            {summaryRows}
        </Accordion>
    );
};

export default PolicySummaryContent;
