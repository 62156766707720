"use client";

import Link from "next/link";
import { SAMPLE_POLICY_URL } from "../utils/constants";
import { PublicConfig } from "@/shared/PublicConfig";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";

export default function FooterContent() {
    // Statsig - reduced-height
    const { hasReducedHeight } = useAppLayerContext().appState;

    return (
        <div className="flex flex-row flex-wrap gap-6  leading-5">
            <Link href={`${PublicConfig.PTZ_US.SPOT_MARKETING_URL}/ca-notice`} target="_blank">
                Spot NOTICE TO CALIFORNIA RESIDENTS
            </Link>

            <Link href={`${PublicConfig.PTZ_US.SPOT_MARKETING_URL}/important-policy-provisions`} target="_blank">
                Insurer Disclosure of Important Policy Provisions
            </Link>

            {hasReducedHeight ? (
                <div>
                    Waiting periods, annual deductible, co-insurance, benefit limits and exclusions may apply. For all terms visit{" "}
                    <Link href={SAMPLE_POLICY_URL}>spotpet.com/sample-policy</Link>. Products, schedules, discounts, and rates may vary and are subject to change. 10% multi-pet
                    discount is available on all pets after the first. More information available at checkout.{""}
                    <Link href={`${PublicConfig.PTZ_US.SPOT_MARKETING_URL}/underwriting`} target="_blank">
                        Insurance plans are underwritten by either Independence American Insurance Company (NAIC #26581. A Delaware insurance company located at 11333 N. Scottsdale
                        Rd, Ste. 160, Scottsdale, AZ 85254) or United States Fire Insurance Company (NAIC #21113. Morristown, NJ), and are produced by Spot Pet Insurance Services,
                        LLC. (NPN # 19246385. 990 Biscayne Blvd Suite 603, Miami, FL 33132. CA License #6000188).
                    </Link>
                </div>
            ) : (
                <>
                    <div>
                        Waiting periods, annual deductible, co-insurance, benefit limits and exclusions may apply. For all terms visit{" "}
                        <Link href={SAMPLE_POLICY_URL}>spotpet.com/sample-policy</Link>. Products, schedules, discounts, and rates may vary and are subject to change. 10% multi-pet
                        discount is available on all pets after the first. More information available at checkout.
                    </div>

                    <div>
                        <Link href={`${PublicConfig.PTZ_US.SPOT_MARKETING_URL}/underwriting`} target="_blank">
                            Insurance plans are underwritten by either Independence American Insurance Company (NAIC #26581. A Delaware insurance company located at 11333 N.
                            Scottsdale Rd, Ste. 160, Scottsdale, AZ 85254) or United States Fire Insurance Company (NAIC #21113. Morristown, NJ), and are produced by Spot Pet
                            Insurance Services, LLC. (NPN # 19246385. 990 Biscayne Blvd Suite 603, Miami, FL 33132. CA License #6000188).
                        </Link>
                    </div>
                </>
            )}
        </div>
    );
}
