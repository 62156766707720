"use client";
// packages
import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRouter, usePathname, useSearchParams } from "next/navigation";
import { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { z } from "zod";
import { useDeepCompareMemo } from "@react-hookz/web";
import toast from "react-hot-toast";

// components
import { Form } from "@/shared/components/Form";
import { FormParentContextProvider } from "@/shared/contexts/FormParent";
import { LoaderModal } from "@/shared/components/LoaderModal";
import { QuoteResetModal } from "@/shared/components/QuoteResetModal";
import { QuoteTimeoutModal } from "@/shared/components/QuoteTimeoutModal";
import { AmazonToast } from "./src/components/AmazonToast";
import { AdminFeeTestWrapper } from "./src/components/AdminFeeTestWrapper";

// hooks
import { useModal } from "@/shared/hooks/useModal";
import { UpdateQuoteMutationParams, useQuote } from "@/shared/hooks/useQuote";
import { useOtherValuesMap } from "@/shared/hooks/useOtherValuesMap";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";
import { useNotifications } from "@/shared/hooks/useNotifications";
import { useDirtyValues } from "@/shared/hooks/useDirtyValues";
import { useBreakpoint } from "@/shared/hooks/useBreakpoint";
import { usePetPlans } from "@/shared/hooks/usePetPlans";
import { useQuoteStatus } from "@/shared/hooks/useQuoteStatus";
import { useDataDogViewTracking } from "@/shared/hooks/useDataDogViewTracking";
import { useStatsigUserUpdate } from "@/shared/hooks/useStatsigUserUpdate";
// import { useBrowserAlert } from "@/shared/hooks/useBrowserAlert";

// utils
import { getUsQuoteForm } from "./formConfig";
import { AnalyticsUtils } from "@/shared/utils/AnalyticsUtils";
import { UIUtils } from "@/shared/utils/UIUtils";
import { StorageUtils } from "@/shared/utils/StorageUtils";
import { QuoteAPI } from "@/shared/utils/QuoteAPI";
import { NotificationUtils } from "@/shared/utils/NotificationUtils";
import Strings from "@/shared/utils/Strings.constants";
import { PTZBillingUtils } from "../../src/types/TempBilling";
import ErrorUtils from "@/shared/utils/ErrorUtils";
import { CookieUtils } from "@/shared/utils/CookieUtils";

// types
import { UsQuoteFormStepIds, UsQuoteFormSteps } from "./formConfigTypes";
import { Quote, QuoteFinalized, QuoteSchema } from "@/shared/types/Quote.interface";
import { FormStep } from "@/shared/types/Form";
import { ComponentWithUnderwriter } from "@/shared/types/AppRouter";
import { Address } from "spot-types/entities/Address";
import { Notifications, notificationSchema } from "@/shared/types/Notifications";
import { PRIORITY_CODE_DEPRECATED } from "@/shared/types/SpotAPI";
import { PetQuote } from "spot-types/entities/PetQuote";

// config
import { PublicConfig } from "@/shared/PublicConfig";
import useDataDogForceRecording from "@/shared/hooks/useDataDogForceRecording";
import { useLayer } from "@statsig/react-bindings";

const FORM_ID = PublicConfig.PTZ_US.FORM_ID;
const COOKIE_QUOTE_ID_KEY = PublicConfig.PTZ_US.COOKIE_QUOTE_ID_KEY;
const ACTIVE_OUTAGES = PublicConfig.PTZ_US.ACTIVE_OUTAGES;
const isDev = PublicConfig.ENVIRONMENT !== "production";

export function UsQuoteFormController({ underwriter, testUserId, pageProps }: ComponentWithUnderwriter) {
    const quoteApi = useMemo(() => new QuoteAPI(underwriter), [underwriter]);
    const [showResetModal, setShowResetModal] = useState(false);
    const isBillingOutage = ACTIVE_OUTAGES.includes("ptz-us-billing-api");
    const currentBreakpoint = useBreakpoint();

    const submittingRef = useRef(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const initialValuesLoadedRef = useRef(false);
    const [isFormReady, setIsFormReady] = useState(false);

    const modal = useModal();
    const { appState, updateAppState } = useAppLayerContext();

    // URL hooks
    const searchParams = useSearchParams();
    const router = useRouter();
    const pathname = usePathname();

    // quoteId
    const quoteIdParam = UIUtils.getCaseInsensitiveValue(new URLSearchParams(searchParams.toString()), `quoteId`);
    const [quoteId, setQuoteId] = useState(() => quoteIdParam ?? ``);

    // DEV ONLY params for testing
    const hasFinalizeTimeoutParam = !!UIUtils.getCaseInsensitiveValue(new URLSearchParams(searchParams.toString()), PublicConfig.PTZ_US.DEV_PARAMS.FINALIZE_TIMEOUT);

    // Server state
    const { queryClient, quoteQuery, updateQuote, isQuoteUpdating } = useQuote({ quoteId, underwriter, setQuoteId });
    const memoizedQuoteQuery = useDeepCompareMemo(() => (!!quoteQuery ? (quoteQuery as UseQueryResult<Quote, Error>) : undefined), [quoteQuery]);
    const memoizedUpdateQuote = useDeepCompareMemo(
        () => (!!updateQuote ? (updateQuote as UseMutationResult<Quote, Error, UpdateQuoteMutationParams, unknown>) : undefined),
        [updateQuote]
    );

    const { data: quote, isError } = memoizedQuoteQuery || {};
    const petPlansObject = usePetPlans(quote, underwriter);

    useQuoteStatus({
        quote,
        isSubmitting,
        cookieQuoteId: COOKIE_QUOTE_ID_KEY,
        cookieFinalizeStarted: PublicConfig.PTZ_US.COOKIE_FINALIZE_STARTED_KEY,
        underwriter,
        formId: FORM_ID
    });

    // Statsig Layer - hasReducedHeight
    const formsMainLayer = useLayer(PublicConfig.STATSIG_LAYER_ID_MAIN);
    const hasReducedHeight = formsMainLayer.__value?.hasReducedHeight as boolean | undefined;

    useEffect(() => {
        updateAppState({ hasReducedHeight: !!hasReducedHeight });
    }, [hasReducedHeight, updateAppState]);
    // End Statsig Layer

    // Verify email param "verifyMC" to toggle Dev Tools member center check "ON" at load time
    const emailRegisterCheck = useMemo(() => {
        const _emailRegisterCheck = UIUtils.getCaseInsensitiveValue(new URLSearchParams(searchParams.toString()), "verifyMC");
        return _emailRegisterCheck === "true";
    }, [searchParams]);

    useEffect(() => {
        if (emailRegisterCheck) {
            updateAppState({ devIsEmailRegisterCheckEnabled: true });
        }
    }, [emailRegisterCheck, updateAppState]);
    // End verifyMC

    // App state
    const {
        stripe,
        stripeElements,
        stripeExpressPaymentType,
        stripeAvailablePaymentMethods,
        devIsEmailRegisterCheckEnabled = emailRegisterCheck,
        isFormLoaderVisible,
        showPolicyDisclaimerBelowCTA,
        asyncErrors,
        isApplyAllHidden,
        showBillingSubmitButton,
        showVideoTestimonials,
        mockAPIError,
        isMarqueeVariant
    } = appState;

    const hasPcodeError = asyncErrors?.some(error => error.id === PRIORITY_CODE_DEPRECATED);

    useEffect(() => {
        if (emailRegisterCheck) {
            updateAppState({ devIsEmailRegisterCheckEnabled: true });
        }
    }, [emailRegisterCheck, updateAppState]);

    // Priority Code
    const priorityCode = useMemo(
        () => UIUtils.getCaseInsensitiveValue(new URLSearchParams(searchParams.toString()), "pcode") || (quote?.affiliateCode ?? quote?.discountCode)?.toLowerCase(),
        [quote?.affiliateCode, quote?.discountCode, searchParams]
    );

    // Removes pcode from URL if it's invalid:
    useEffect(() => {
        if (hasPcodeError) {
            const newURL = UIUtils.deleteQueryParamFromUrl("pcode", searchParams, pathname);
            router.push(newURL, { scroll: false });
        }
    }, [hasPcodeError, asyncErrors, updateAppState, searchParams, pathname, router]);

    // Form state
    const form = useForm<Quote>({
        resolver: zodResolver(QuoteSchema),
        defaultValues: { underwriter, extra: { queryParams: AnalyticsUtils.getQueryParams(), testUserId } }
    });

    const {
        setValue,
        getValues,
        reset,
        setError,
        control,
        formState: { errors }
    } = form;
    const formValues = useWatch({ control });

    const { resetDirtyValues } = useDirtyValues<Quote>();

    const { otherValuesMap } = useOtherValuesMap();

    const stepId = useMemo(() => {
        const foundLastStepID = formValues.lastStepID ?? quote?.lastStepID ?? quote?.extra?.lastStepID;
        const defaultStep = (foundLastStepID ?? UsQuoteFormSteps.PETS.id) as UsQuoteFormStepIds;

        const lastStepIdDiff = UIUtils.getTimeDiffNow(quote?.extra?.lastStepIDUpdatedAt as string | undefined, "minutes");

        if (!isBillingOutage) {
            if (defaultStep === UsQuoteFormSteps["BILLING-OUTAGE"].id) {
                return UsQuoteFormSteps.COVERAGE.id;
            }
        }

        if (isBillingOutage && defaultStep === UsQuoteFormSteps.BILLING.id) {
            return UsQuoteFormSteps["BILLING-OUTAGE"].id;
        }

        // If the user has a lastStepID and it's been more than X minutes, send them to the step below
        if (defaultStep && defaultStep === UsQuoteFormSteps.BILLING.id && lastStepIdDiff > PublicConfig.MAX_RETURN_TO_QUOTE_MINUTES) {
            return UsQuoteFormSteps.COVERAGE.id;
        }

        return defaultStep;
    }, [formValues.lastStepID, isBillingOutage, quote?.extra?.lastStepID, quote?.extra?.lastStepIDUpdatedAt, quote?.lastStepID]);

    const [currentStep, setCurrentStep] = useState<UsQuoteFormStepIds>(stepId);

    useEffect(() => {
        updateAppState({ currentStepID: currentStep });
    }, [updateAppState, currentStep]);

    // Methods

    const resetQuote = useCallback(() => {
        queryClient.clear();
        setShowResetModal(false);
        setQuoteId(``);
        setCurrentStep(UsQuoteFormSteps.PETS.id);
        CookieUtils.remove(COOKIE_QUOTE_ID_KEY);
        resetDirtyValues();
        const newSearchParams = new URLSearchParams(searchParams.toString());
        newSearchParams.delete("quoteId");
        const newUrl = `${pathname}?${newSearchParams.toString()}`;
        reset({ underwriter });
        router.push(newUrl);
    }, [pathname, queryClient, reset, resetDirtyValues, router, searchParams, underwriter]);

    const generateThankYouUrl = useCallback(() => {
        const params = new URLSearchParams(searchParams.toString());
        const _quoteIdInParams = UIUtils.getCaseInsensitiveValue(params, "quoteId");
        const _underwriter = UIUtils.getCaseInsensitiveValue(params, "uw");
        const _quoteId = quote?.id;

        if (!!_quoteId && !_quoteIdInParams) {
            params.set(`quoteId`, _quoteId);
        }

        if (!_underwriter) {
            params.set(`uw`, underwriter);
        }

        return UIUtils.toUrlString(`${PublicConfig.BASE_PATH}/forms/${FORM_ID}/thankyou`, params);
    }, [quote?.id, searchParams, underwriter]);

    const generateQuoteWithRatingAddress = (quote: QuoteFinalized) => {
        const ratingAddressFull: Address = {
            ...quote.ratingAddress,
            city: quote.billingInfo.address?.city ?? ``,
            street1: quote.billingInfo.address?.street1 ?? ``,
            street2: quote.billingInfo.address?.street2 ?? ``,
            country: "US"
        };

        // #statsig - policy-step-name-collection - account for users who don't see the first/last name fields on step 1
        quote.firstName = (quote?.firstName ?? quote.billingInfo.firstName).trim();
        quote.lastName = (quote?.lastName ?? quote.billingInfo.lastName).trim();

        // For partner users (ie, from Forbes) who enter the quote flow with a pre-populated first/last name
        const isPetParent = quote.firstName.toLowerCase() === "pet" && quote.lastName.toLowerCase() === "parent";
        if (isPetParent) {
            quote.firstName = quote.billingInfo.firstName;
            quote.lastName = quote.billingInfo.lastName;
        }

        const quotePayloadWithRatingAddress: Quote = {
            ...quote,
            ratingAddress: ratingAddressFull,
            billingInfo: { frequency: quote.billingInfo.frequency },
            extra: { ...quote.extra }
        };

        return quotePayloadWithRatingAddress;
    };

    // Final submit
    /*
const [isFinalizing, setIsFinalizing] = useState(quote?.quoteStatus === "finalize-processing");
useBrowserAlert({ isEnabled: isFinalizing });
*/

    const onSubmit: SubmitHandler<QuoteFinalized> = useCallback(
        async values => {
            if (submittingRef.current) {
                throw new Error("PAYMENT - Form is already submitting");
            }
            if (!stripe) {
                throw new Error("PAYMENT - Stripe is not ready");
            }
            if (!stripeElements) {
                throw new Error("PAYMENT - Stripe Elements is not ready");
            }

            submittingRef.current = true;
            setIsSubmitting(true);
            toast.dismiss();

            if (appState.asyncErrors?.length) {
                updateAppState({ asyncErrors: undefined });
            }

            try {
                if (!values.id) throw new Error("PAYMENT - Quote ID is missing");

                const { billingInfo } = values;
                // Update quote with billing address and update quote
                const quoteWithRatingAddress = generateQuoteWithRatingAddress({
                    ...values,
                    extra: { ...values.extra, stripe: { paymentType: stripeExpressPaymentType ?? `card`, availablePaymentMethods: stripeAvailablePaymentMethods } }
                });

                const updateFinalQuote = async () => {
                    try {
                        return await memoizedUpdateQuote?.mutateAsync({ quote: quoteWithRatingAddress });
                    } catch (error) {
                        const hasPcodeError = ErrorUtils.hasSpotError(error, PRIORITY_CODE_DEPRECATED);
                        if (hasPcodeError) {
                            let retryData = { ...quoteWithRatingAddress, affiliateCode: "SPOT", discountCode: "SPOT" };
                            if (PublicConfig.ENVIRONMENT === "development" && mockAPIError) {
                                const updatedExtra = { ...retryData.extra, sim: "" };
                                retryData = { ...retryData, extra: updatedExtra };
                            }
                            const finalQuote = await memoizedUpdateQuote?.mutateAsync({ quote: retryData });
                            return finalQuote;
                        }
                    }
                };

                const finalQuote = await updateFinalQuote();

                // Create confirmation token to finalize payment
                // https://docs.stripe.com/js/confirmation_tokens/create_confirmation_token
                // https://docs.stripe.com/payments/finalize-payments-on-the-server?platform=web&type=payment#create-ct
                const { error: tokenError, confirmationToken } = await stripe.createConfirmationToken({
                    elements: stripeElements,
                    params: {
                        payment_method_data: {
                            billing_details: {
                                name: UIUtils.truncateString(billingInfo.nameOnCard, 50, false),
                                address: {
                                    line1: billingInfo.address?.street1,
                                    line2: billingInfo.address?.street2,
                                    city: billingInfo.address?.city,
                                    state: billingInfo.address?.state,
                                    postal_code: billingInfo.address?.zipCode,
                                    country: "US"
                                },
                                email: values.email,
                                phone: values.phone
                            }
                        }
                    }
                });

                // Only reached if there's an immediate error when generating the token
                if (tokenError) {
                    throw tokenError;
                    // show generic "check your payment details" error
                }

                if (!confirmationToken?.id) {
                    throw new Error("PAYMENT - Could not generate confirmation token");
                }

                if (!finalQuote?.id) {
                    throw new Error("PAYMENT - Could not update quote");
                }

                const thankYouUrl = generateThankYouUrl();
                const card = confirmationToken.payment_method_preview.card;

                // Update billing information with Stripe token
                await QuoteAPI.updateBillingInformation(finalQuote.id, {
                    billingType: "STRIPE",
                    billingFrequency: billingInfo.frequency === "monthly" ? "Monthly" : "Annually",
                    stripeConfirmationToken: confirmationToken.id,
                    creditCardType: PTZBillingUtils.mapCreditCardBrand(card?.brand ?? ""),
                    expirationDate: PTZBillingUtils.convertStripeExpDetails({ month: card?.exp_month ?? 0, year: card?.exp_year ?? 0 }),
                    nameOnAccount: UIUtils.truncateString(billingInfo.nameOnCard, 50, false),
                    accountNumber: card?.last4 ?? "",
                    accountNumberLast4: card?.last4 ?? ""
                });

                let finalizedQuote: PetQuote | undefined = undefined;
                // Finalize quote to complete the payment and start the user's policy
                try {
                    CookieUtils.set(PublicConfig.PTZ_US.COOKIE_FINALIZE_STARTED_KEY, "true");
                    // setIsFinalizing(true);
                    if (isDev && hasFinalizeTimeoutParam) {
                        // For testing finalize timeout with Playwright, we first finalize the quote, then we attempt to finalize again with the simulated timeout
                        await quoteApi.finalizeQuote(finalQuote.id);
                        await quoteApi.finalizeQuote(finalQuote.id, true);
                    } else {
                        finalizedQuote = await quoteApi.finalizeQuote(finalQuote.id);
                    }
                } catch (error) {
                    // Here we refetch the quote data in the event of a timeout or network error to determine if the quote was finalized
                    if (isAxiosError(error)) {
                        const { request, response } = error;
                        const networkError = !response && request?.status === 0; // network error
                        const timeoutError = response && response.status === 504; // gateway timeout

                        if (networkError || timeoutError) {
                            submittingRef.current = false;
                            setIsSubmitting(false);
                            queryClient.invalidateQueries({ queryKey: ["quote"] });
                            return;
                        }
                        queryClient.invalidateQueries({ queryKey: ["quote"] });
                        throw error;
                    } else {
                        CookieUtils.remove(PublicConfig.PTZ_US.COOKIE_FINALIZE_STARTED_KEY);
                        // setIsFinalizing(false);
                        throw error;
                    }
                }

                // Clean up and redirect
                if (finalizedQuote?.quoteStatus === "finalized") {
                    queryClient.clear();
                    CookieUtils.remove(COOKIE_QUOTE_ID_KEY);
                    await UIUtils.scrollToTop(3000, 50);
                    router.push(thankYouUrl);
                } else {
                    queryClient.invalidateQueries({ queryKey: ["quote"] });
                }
            } catch (err: any) {
                submittingRef.current = false;
                setIsSubmitting(false);
                if (err instanceof z.ZodError) {
                    AnalyticsUtils.sendDataDogLog("error", "PAYMENT - Zod validation error in quote submission", {
                        quoteId: values.id,
                        issues: err.issues.map(issue => ({ path: issue.path.join("."), message: issue.message }))
                    });
                    err.issues.forEach(issue => {
                        // Assuming the 'path' of the issue is directly mappable to your form fields
                        // You might need to adjust if your form fields/names are structured differently
                        const path = issue.path.join(".");
                        setError(path as keyof QuoteFinalized, {
                            type: "manual",
                            message: issue.message
                        });
                    });
                }
                if (isAxiosError(err)) {
                    const errorStatus = err?.response?.status ?? 0;

                    // Handle Spot API errors with error ids
                    if (ErrorUtils.isSpotApiError(err.response?.data)) {
                        const asyncErrors = ErrorUtils.getErrorIds(err.response?.data);
                        AnalyticsUtils.sendDataDogLog("error", "PAYMENT - Spot API error in quote submission", {
                            quoteId: values.id,
                            errorStatus,
                            asyncErrors
                        });
                        updateAppState({ asyncErrors, isQuoteUpdating: false, showFinalLoader: false });
                        return;
                    }

                    // Handle non Spot API errors that have some error message (ex: invalid billing zipcode)
                    const error = err.response?.data ?? err.message;
                    if (typeof error === "string" && error.includes("Billing Zipcode") && error.includes("not found")) {
                        const newError = ErrorUtils.ptzZipcodeErrorToSpotError();
                        const asyncErrors = ErrorUtils.getErrorIds(newError);
                        AnalyticsUtils.sendDataDogLog("error", "PAYMENT - Billing zipcode error in quote submission", {
                            quoteId: values.id,
                            error,
                            errorStatus,
                            asyncErrors
                        });
                        updateAppState({ asyncErrors, isQuoteUpdating: false, showFinalLoader: false });
                        return;
                    }

                    // For unhandled errors, throw a generic "unknown" error to be caught by the global error handler (ex: hasUnknownError)
                    if (errorStatus >= 400) {
                        AnalyticsUtils.sendDataDogLog("error", "PAYMENT - Unknown error in quote submission", {
                            quoteId: values.id,
                            errorStatus,
                            errorMessage: err.message
                        });
                        updateAppState({ hasUnknownError: true, isQuoteUpdating: false, showFinalLoader: false });
                        return;
                    }
                } else {
                    // Handle async Stripe errors
                    const stripeErrorTypes = ["card_error"];
                    const isStripeError = !!err?.type && stripeErrorTypes.includes(err?.type);

                    if (isStripeError) {
                        updateAppState({ isQuoteUpdating: false, showFinalLoader: false, stripePaymentElementError: { stripe: true, message: err.message } });
                    } else {
                        updateAppState({ hasUnknownError: true, isQuoteUpdating: false, showFinalLoader: false });
                    }

                    AnalyticsUtils.sendDataDogLog("error", "PAYMENT - Unexpected error in quote submission", {
                        quoteId: values.id,
                        errorMessage: err instanceof Error ? err.message : typeof err === "object" ? JSON.stringify(err) : String(err)
                    });
                }
                throw err;
            }
        },
        [
            appState.asyncErrors?.length,
            generateThankYouUrl,
            hasFinalizeTimeoutParam,
            memoizedUpdateQuote,
            queryClient,
            quoteApi,
            router,
            setError,
            stripe,
            stripeAvailablePaymentMethods,
            stripeElements,
            stripeExpressPaymentType,
            updateAppState,
            mockAPIError
        ]
    );

    const updateCurrentStep = useCallback(
        (stepId: UsQuoteFormStepIds, quoteId?: string) => {
            const params = new URLSearchParams(searchParams.toString());
            const _quoteIdInParams = UIUtils.getCaseInsensitiveValue(params, "quoteId");
            const _quoteId = quoteId;

            if (!!_quoteId && !_quoteIdInParams) {
                params.set(`quoteId`, _quoteId);
            }

            const newUrl = `${PublicConfig.BASE_PATH}${pathname}?${params.toString()}`;
            window.history.pushState({ currentStep: stepId }, "", newUrl);

            setCurrentStep(stepId);
            resetDirtyValues();
            if (!!appState.asyncErrors?.length) {
                updateAppState({ asyncErrors: undefined });
            }
        },
        [appState.asyncErrors?.length, pathname, resetDirtyValues, searchParams, updateAppState]
    );

    const handleStepChange = useCallback(
        async (newStepIndex: number, newStep: FormStep<Quote, UsQuoteFormStepIds, keyof Quote>, value?: Quote) => {
            if (!newStep?.id) return;

            updateCurrentStep(newStep.id, value?.id);
        },
        [updateCurrentStep]
    );

    // DataDog Recording
    useDataDogForceRecording({
        isFeatureEnabled: PublicConfig.FEATURE_DD_FORCE_RECORDING_ENABLED,
        isRecordingEnabled: !!appState.isRecordingEnabled
    });

    // Form
    const quoteForm = useMemo(
        () =>
            getUsQuoteForm({
                underwriter,
                updateQuote: setValue,
                updateAppState,
                isUpdating: !!appState?.isQuoteUpdating,
                queryClient,
                quoteId,
                setQuoteId,
                onSubmit,
                currentStep,
                priorityCode: priorityCode,
                isApplyAllHidden,
                updateCurrentStep,
                showBillingSubmitButton,
                devIsEmailRegisterCheckEnabled,
                showVideoTestimonials,
                showPolicyDisclaimerBelowCTA,
                mockAPIError,
                isMarqueeVariant
            }),
        [
            underwriter,
            setValue,
            updateAppState,
            appState?.isQuoteUpdating,
            queryClient,
            quoteId,
            onSubmit,
            currentStep,
            priorityCode,
            isApplyAllHidden,
            updateCurrentStep,
            showBillingSubmitButton,
            devIsEmailRegisterCheckEnabled,
            showVideoTestimonials,
            showPolicyDisclaimerBelowCTA,
            mockAPIError,
            isMarqueeVariant
        ]
    );

    const { steps } = quoteForm;

    const checkStepsRunningRef = useRef(false);
    const [isStepCheckFinished, setIsStepCheckFinished] = useState(false);

    // This effect runs when a quote loads and validates the current quote data against the stepSchema and allowContinue
    // methods of each step until it finds a step that is not valid.  It then sets the lastStepID to the id of the invalid step and calls handleStepChange
    useEffect(() => {
        const checkSteps = async () => {
            try {
                for (const [index, stepEntry] of steps.entries()) {
                    const { stepSchema, id, shouldSkip, allowContinue, isMaxDeterministicStep } = stepEntry;

                    if (index === steps.length - 1) {
                        // if we have reached the last step, we can skip all checks below
                        setValue(`lastStepID`, id);
                        handleStepChange(index, stepEntry, quote);
                        break;
                    }

                    if (shouldSkip) {
                        // if the step shouldSkip boolean is true, we can skip all checks below
                        continue;
                    }

                    if (isMaxDeterministicStep) {
                        const maxStepIndex = index;
                        const quoteStepIndex = steps.findIndex(step => step.id === quote?.lastStepID);
                        const lastStepIdDiff = UIUtils.getTimeDiffNow(quote?.extra?.lastStepIDUpdatedAt as string | undefined, "minutes");

                        // Cases for breaking out of the loop:
                        const noLastStepIdInQuote = !quote?.lastStepID;
                        const quoteLastStepIdIsMaxStep = quoteStepIndex === maxStepIndex;
                        const quoteLastStepTimeDiffExceeded = quoteStepIndex > maxStepIndex && lastStepIdDiff > PublicConfig.MAX_RETURN_TO_QUOTE_MINUTES;

                        const shouldBreak = noLastStepIdInQuote || quoteLastStepIdIsMaxStep || quoteLastStepTimeDiffExceeded;

                        if (shouldBreak) {
                            setValue(`lastStepID`, id);
                            handleStepChange(index, stepEntry, quote);
                            break;
                        }
                    }

                    // check the quote against the current stepSchema if it exists
                    const isValid = stepSchema?.safeParse(quote);

                    if (!!stepSchema && !isValid?.success) {
                        // if the quote data is not valid against the stepSchema, set the lastStepID to the id of the current step
                        setValue(`lastStepID`, id);
                        handleStepChange(index, stepEntry, quote);
                        break;
                    }

                    if ((isValid?.success || !stepSchema) && !!quote?.lastStepID) {
                        // if the quote data is valid against the stepSchema and the quote has a lastStepID,
                        // we can skip all checks below and continue to next step
                        continue;
                    }

                    if (!!allowContinue) {
                        // if the step has an allowContinue method, check if we can continue to the next step
                        const canContinue = await allowContinue(quote, otherValuesMap[id]);
                        if (canContinue) {
                            continue;
                        }
                    }

                    // if allowContinue returns false, set the lastStepID to the id of the current step and call handleStepChange
                    setValue(`lastStepID`, id);
                    handleStepChange(index, stepEntry, quote);
                    break;
                }
            } catch (error) {
                console.error("Error in checkSteps:", error);
            } finally {
                setIsStepCheckFinished(true);
            }
        };

        if (!checkStepsRunningRef.current && isFormReady && !submittingRef.current) {
            checkStepsRunningRef.current = true;
            if (!!quote?.id && !!steps.length && quote?.quoteStatus !== "finalized") {
                checkSteps();
            } else {
                setIsStepCheckFinished(true);
            }
        }
    }, [handleStepChange, isFormReady, otherValuesMap, quote, setValue, steps]);

    useEffect(() => {
        if (!!stepId && isStepCheckFinished) {
            setCurrentStep(stepId);
        }
    }, [isStepCheckFinished, stepId]);

    // PRIORITY CODE
    const pcodeCheckRunningRef = useRef(false);

    const browserPCode = UIUtils.getCaseInsensitiveValue(new URLSearchParams(searchParams.toString()), "pcode");

    useEffect(() => {
        // If the browser has a pCode and a quote ID exists, we should update the quote with it. Note this means that last pcode wins and overwrites previous pcode, but
        // the API will overrule us if the new pcode has a lesser discount. So there is no guarantee this pcode will stick!
        const updatePcode = async () => {
            try {
                // If the quote already exists, go ahead and update our API with the new pcode. Otherwise, it will be updated when the form
                // has changes in the future.
                if (!!quote?.id) {
                    const currentValues = getValues();
                    const updatedQuote = await memoizedUpdateQuote?.mutateAsync({
                        quote: {
                            ...quote,
                            ...currentValues,
                            discountCode: hasPcodeError ? "SPOT" : browserPCode,
                            affiliateCode: hasPcodeError ? "SPOT" : browserPCode,
                            lastStepID: currentStep
                        }
                    });
                    setValue(`discountCode`, updatedQuote?.discountCode);
                    setValue(`affiliateCode`, updatedQuote?.affiliateCode);
                } else {
                    setValue(`discountCode`, hasPcodeError ? "SPOT" : browserPCode);
                    setValue(`affiliateCode`, hasPcodeError ? "SPOT" : browserPCode);
                }
            } catch (error) {
                console.error("Error updating priority code");
            }
        };

        if (
            !pcodeCheckRunningRef.current &&
            isStepCheckFinished &&
            !!browserPCode &&
            (quote?.discountCode ?? quote?.affiliateCode) !== browserPCode &&
            isFormReady &&
            !appState?.isQuoteUpdating &&
            quote?.quoteStatus !== "finalized" &&
            quote?.quoteStatus !== "finalize-processing"
        ) {
            pcodeCheckRunningRef.current = true;
            updatePcode();
        }
    }, [
        appState?.isQuoteUpdating,
        browserPCode,
        currentStep,
        formValues.lastStepID,
        getValues,
        hasPcodeError,
        isFormReady,
        isStepCheckFinished,
        memoizedUpdateQuote,
        quote,
        setValue
    ]);

    const handleSubmitEffects = async (value: Quote, step: FormStep<Quote, UsQuoteFormStepIds, keyof Quote>): Promise<string | void> => {
        /* For any side effects we need to run on submitting a step */
        if (step.allowSubmit && step.allowSubmit(value, otherValuesMap[step.id])) {
        }
    };

    // Effects

    // Show reset modal if Quote is in a bad state: invalid quote ID or quote update error
    useEffect(() => {
        if ((memoizedUpdateQuote?.isError && currentStep === UsQuoteFormSteps.COVERAGE.id) || memoizedQuoteQuery?.isError) {
            const error = memoizedUpdateQuote?.error;
            const hasMemoizedPcodeError = ErrorUtils.hasSpotError(error, PRIORITY_CODE_DEPRECATED);

            // Don't show reset modal in these cases:
            if (hasPcodeError || hasMemoizedPcodeError) {
                return;
            }
            setShowResetModal(true);
        }
    }, [memoizedUpdateQuote?.isError, memoizedQuoteQuery?.isError, hasPcodeError, memoizedUpdateQuote?.error, currentStep]);

    useEffect(() => {
        const handlePopState = async (event: PopStateEvent) => {
            const stateStepId = window.history.state?.currentStep;
            if (stateStepId) {
                setCurrentStep(stateStepId as UsQuoteFormStepIds);
            } else {
                setCurrentStep(UsQuoteFormSteps.PETS.id);
            }
        };

        // Add the async event listener
        window.addEventListener("popstate", handlePopState);

        // Clean up the event listener
        return () => window.removeEventListener("popstate", handlePopState);
    }, []);

    useEffect(() => {
        // Push underwriter value into AppLayer for use in other components
        if (!!underwriter) {
            updateAppState({ underwriter });
        }
    }, [underwriter, updateAppState]);

    useEffect(() => {
        // Sets initial form values once the quote ID is available
        if (!initialValuesLoadedRef.current && !quoteIdParam) {
            initialValuesLoadedRef.current = true;
            setIsFormReady(true);
        }

        if (!initialValuesLoadedRef.current && quoteId && !!quote?.id) {
            initialValuesLoadedRef.current = true;
            const allValues = getValues();
            const merged = { ...quote, ...allValues, underwriter, extra: { ...quote.extra } };
            reset(merged);
            if ((quote?.quoteStatus === "open" && !CookieUtils.exists(PublicConfig.PTZ_US.COOKIE_FINALIZE_STARTED_KEY)) || quote?.quoteStatus === "failed-authorization") {
                setIsFormReady(true);
            }
        }
    }, [quoteId, quote, reset, getValues, quoteIdParam, formValues.id, underwriter, formValues.lastStepID]);

    useEffect(() => {
        // Here we update the form values from the API data
        if (!!quote?.id) {
            reset(quote);

            if (isDev) {
                StorageUtils.setItem(`dev-quote`, JSON.stringify(quote));
            }
        }
    }, [quote, reset]);

    useEffect(() => {
        if (!!memoizedQuoteQuery) {
            updateAppState({ quoteQuery: memoizedQuoteQuery });
        }
    }, [memoizedQuoteQuery, updateAppState]);

    useEffect(() => {
        if (!!memoizedUpdateQuote) {
            updateAppState({ updateQuote: memoizedUpdateQuote });
        }
    }, [memoizedUpdateQuote, updateAppState]);

    useEffect(() => {
        updateAppState({ isQuoteUpdating: isQuoteUpdating });
    }, [isQuoteUpdating, updateAppState]);

    useEffect(() => {
        updateAppState({ breakpoint: currentBreakpoint });
    }, [currentBreakpoint, updateAppState]);

    useEffect(() => {
        updateAppState({ priorityCode: priorityCode });
    }, [priorityCode, updateAppState]);

    useEffect(() => {
        updateAppState({ isAnnualBilling: formValues?.billingInfo?.frequency === "yearly" });
    }, [formValues?.billingInfo?.frequency, updateAppState]);

    useEffect(() => {
        if (!!quote?.id && !!petPlansObject) {
            updateAppState({ petPlansObject });
        }
    }, [petPlansObject, quote?.id, updateAppState]);

    //
    // NOTIFICATIONS
    //

    const handleNotificationClose = useCallback(
        (notificationToUpdate: z.infer<typeof notificationSchema>) => {
            if (submittingRef.current) return;
            const currentExtra = getValues("extra");
            const existingNotifications = currentExtra?.notifications || [];

            // Check if the notification already exists based on both id and type
            const notificationIndex = existingNotifications.findIndex(
                notification => notification.id === notificationToUpdate.id && notification.type === notificationToUpdate.type
            );

            if (notificationIndex > -1) {
                // Update existing notification
                existingNotifications[notificationIndex] = { ...existingNotifications[notificationIndex], ...notificationToUpdate };
            } else {
                // Notification doesn't exist, append the new notification
                existingNotifications.push(notificationToUpdate);
            }

            const updatedExtra = {
                ...currentExtra,
                notifications: existingNotifications
            };
            // Update the whole state with the new notifications array
            setValue("extra", updatedExtra);
        },
        [getValues, setValue]
    );

    const formNotifications: Notifications = useMemo(() => {
        const amazonGiftCardToast = formValues?.extra?.notifications?.find(toast => toast.type === "toast" && toast.id === "amazon-gift-card");

        return {
            toasts: [
                {
                    id: "amazon-gift-card",
                    type: "toast",
                    message: <AmazonToast state={formValues?.ratingAddress?.state} />,
                    visible:
                        !!formValues?.id &&
                        !submittingRef.current &&
                        formValues?.quoteStatus !== "finalized" &&
                        NotificationUtils.isAmazonEligible(formValues?.ratingAddress?.state) &&
                        NotificationUtils.shouldShowNotification(amazonGiftCardToast?.lastClosed, amazonGiftCardToast?.frequency),
                    duration: Infinity,
                    position: "top-center",
                    lastClosed: amazonGiftCardToast?.lastClosed,
                    frequency: "weekly",
                    isCloseDisabled: appState?.isQuoteUpdating,
                    onClose: () => handleNotificationClose({ id: "amazon-gift-card", type: "toast", frequency: "weekly", lastClosed: new Date().toISOString() })
                }
            ]
        };
    }, [formValues?.extra?.notifications, formValues?.ratingAddress?.state, formValues?.id, formValues?.quoteStatus, appState?.isQuoteUpdating, handleNotificationClose]);

    useNotifications({ notifications: formNotifications });

    useEffect(() => {
        if ((!!quote?.id && !isStepCheckFinished) || petPlansObject.isPetPlansUpdating || quote?.quoteStatus === "finalized") {
            updateAppState({ isFormLoaderVisible: true });
        } else {
            updateAppState({ isFormLoaderVisible: false });
        }
    }, [isStepCheckFinished, petPlansObject.isPetPlansUpdating, quote?.id, quote?.quoteStatus, updateAppState]);

    // If a user moves back to a previous step via popstate, we need to check if the next step had hideBackButton set to true
    // If it did, we need to setCurrentStep to the next step
    useEffect(() => {
        const scrollToTop = async () => {
            await UIUtils.scrollToTop(3000, 50);
        };

        if (!!quote?.id && isStepCheckFinished) {
            const currentStepIndex = quoteForm.steps.findIndex(step => step.id === currentStep);
            const nextStep = quoteForm.steps[currentStepIndex + 1];
            if (!!nextStep?.hideBackButton && !nextStep?.shouldSkip) {
                setCurrentStep(nextStep.id);
                scrollToTop();
            }
        }
    }, [quoteForm.steps, quote?.id, isStepCheckFinished, currentStep, router]);

    useDataDogViewTracking({ formId: FORM_ID, formStepId: currentStep, isEnabled: isStepCheckFinished });
    useStatsigUserUpdate({ quote: quote });

    return (
        <>
            {appState.showFinalLoader && <LoaderModal />}
            <QuoteResetModal onReset={resetQuote} isVisible={showResetModal} />
            {!appState.showFinalLoader && !showResetModal && !!appState?.isEnrollmentTimeoutModalVisible && (
                <QuoteTimeoutModal isVisible={appState.isEnrollmentTimeoutModalVisible} />
            )}
            <FormParentContextProvider methods={form}>
                <Form
                    key={currentStep}
                    form={quoteForm}
                    initialStepID={currentStep}
                    value={formValues}
                    otherValuesMap={otherValuesMap}
                    isUpdating={isFormLoaderVisible}
                    onStepChange={handleStepChange}
                    onSubmitStep={handleSubmitEffects}
                    uwConfig={{ phone: Strings.PTZ_US.PHONE_NUMBER, hours: Strings.PTZ_US.HOURS }}
                />
                <AdminFeeTestWrapper isEnabled={!!quote?.id && currentStep !== UsQuoteFormSteps.PETS.id} />
            </FormParentContextProvider>
            {modal.render}
        </>
    );
}
